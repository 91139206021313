<template>
  <div>
    <div class="table-container-section">
      <div class="table-container-section__table-view">
        <TableComponent :await-for-season="false" />
      </div>
    </div>
  </div>
</template>
<script>
import TableComponent from '@/components/Sections/Table/TableComponent';
export default {
  name: 'Table',
  components: {
    TableComponent,
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.table-container-section {
  &__table-view {
    max-width: 548px;
    margin: 0 auto;
  }
}
</style>
